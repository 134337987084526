import React, { FC } from 'react';
import classNames from 'classnames';

import { Props } from './Social.types';

import styles from './Social.module.scss';

export const Social: FC<Props> = ({ className, icon: Icon, label, link }) => (
  <a
    target="_blank"
    rel="noreferrer"
    href={link}
    className={classNames(styles.container, className)}
  >
    <Icon />
    <p className={styles.label}>{label}</p>
  </a>
);
