import React from 'react';

import { useTranslation } from 'react-i18next';

import { Modal } from '../../Modal';

import { ReactComponent as TelSvg } from '../../Footer/assets/images/tel.svg';
import styles from './Register.module.scss';

export const Register = () => {
  const { t } = useTranslation();
  return (
    <Modal id="register">
      <div className={styles.container}>
        <h3 className={styles.title}>{t('register.title')}</h3>
        <span>{t('register.subtitle')}</span>
        <div className={styles.button}>
          <TelSvg />
          <a target="_blank" href="https://t.me/ManagerLafabet" rel="noreferrer">
            @ManagerLafabet
          </a>
        </div>
      </div>
    </Modal>
  );
};
