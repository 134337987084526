/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useTranslation } from 'react-i18next';

import { motion } from 'framer-motion';

import { useParallax } from 'react-scroll-parallax';

import { MainScreenContainer } from '../MainScreenContainer';
import { experienceList } from './Experience.data';
import { Item } from './Item';
import useWindowSize from '../../hooks/useWindowSize';
import { ReactComponent as ArrowSvg } from './assets/images/arrow.svg';
import styles from './Experience.module.scss';
import { ArrowIcon } from '../../ui/icons';
// import { ReactComponent as BackSvg } from './assets/images/back.svg';
import Background from './assets/images/mobileBackFirstSection.jpg';
import Video from './assets/images/small_back.mp4';

export const Experience = () => {
  const size = useWindowSize();
  const parallax = useParallax<HTMLDivElement>({
    translateY: [-200, 200],
  });
  const { t } = useTranslation();
  const textAnimation = {
    hidden: {
      x: -100,
      opacity: 0,
    },
    visible: {
      transition: { delay: 0.1, duration: 0.8 },
      x: 0,
      opacity: 1,
    },
  };
  const buttonAnimation = {
    hidden: {
      opacity: 0,
    },
    visible: {
      transition: { delay: 0.1, duration: 0.8 },
      opacity: 1,
    },
  };
  const itemAnimation = {
    hidden: {
      y: 100,
      opacity: 0,
    },
    visible: (custom: number) => ({
      y: 0,
      transition: { delay: custom * 0.2, duration: 0.8 },
      opacity: 1,
    }),
  };
  return (
    <motion.div
      initial={size.width > 1000 ? 'hidden' : 'visible'}
      whileInView="visible"
      viewport={{ amount: 0.2, once: true }}
      className={styles.experience}
    >
      <div ref={parallax.ref} className={styles.video}>
        <video preload="auto" loop muted autoPlay src={size.width > 768 ? Video : ''} />
      </div>

      <a href="#registration">
        <motion.button variants={buttonAnimation} className={styles.link}>
          <p className={styles.label}>{t('experience.label')}</p>
          <div className={styles.svg}>
            <ArrowIcon />
          </div>
        </motion.button>
      </a>
      <MainScreenContainer>
        <div className={styles.titleContainer}>
          <div className={styles.mobileBackground}>
            <img src={Background} alt="" />
          </div>
          <motion.h3 variants={textAnimation} className={styles.title}>
            {t('experience.title')}
          </motion.h3>
        </div>
        <div className={styles.row}>
          <motion.button variants={buttonAnimation} type="button" className={styles.arrow}>
            <a href="#registration">
              <ArrowSvg />
            </a>
          </motion.button>
          <ul className={styles.list}>
            {experienceList().map((item, index) => (
              <motion.li variants={itemAnimation} custom={index + 3} key={index}>
                <Item {...item} />
              </motion.li>
            ))}
          </ul>
        </div>
      </MainScreenContainer>
      {/* <div className={styles.background}>
        <BackSvg />
      </div> */}
    </motion.div>
  );
};
