import React from 'react';

import { useTranslation } from 'react-i18next';

import styles from './PolicyParagraph.module.scss';

interface Props {
  data: string[];
}

export const PolicyParagraph = ({ data }: Props) => {
  const { t } = useTranslation();
  return (
    <>
      {data.map((elem, index) => (
        <p className={styles.paragraph} key={index}>
          {t(elem)}
        </p>
      ))}
    </>
  );
};
