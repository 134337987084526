import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { openModal } from '../app';
import { AsyncThunkApi, FetchRegisterPayload, FetchRegisterReturn } from './types';

export const fetchRegister = createAsyncThunk<
  FetchRegisterReturn,
  FetchRegisterPayload,
  AsyncThunkApi
>('upload/fetchRegister', async (payload, { dispatch, rejectWithValue }) => {
  const { email, password, contact } = payload;
  const emailData = `${email.split('@')[0]}@lafabet.partners`;

  const dataUser = {
    email: emailData,
    password,
    contact,
  };
  let formData = new FormData();
  formData.append('data', JSON.stringify(dataUser));
  try {
    const { data, status } = await axios.post(
      'https://partners.service.lafa.bet/api/tasks',
      formData,
    );

    if (status === 201 || status === 200) {
      dispatch(openModal('register'));
      return data;
    }
    if (status === 400) {
      dispatch(openModal('error'));
      return 'Ошибка загрузки изображения';
    }
    return rejectWithValue('Произошла ошибка');
  } catch (error) {
    dispatch(openModal('error'));
    return rejectWithValue(error.message);
  }
});
