import { combineReducers } from 'redux';

import modalsReducer from './modals';

const reducer = combineReducers({
  modals: modalsReducer,
});

export * from './modals';

export default reducer;
