import React from 'react';

import { useTranslation } from 'react-i18next';
import { hideModal, openModal } from 'src/store/app';
import { useAppDispatch } from 'src/store/appHooks';
import { Input, Button } from '../../../ui';
import { Modal } from '../../Modal';

import styles from './Login.module.scss';

export const Login = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const handleForgotPassword = (e: React.MouseEvent) => {
    e.preventDefault();
    dispatch(hideModal('login'));
    dispatch(openModal('forgot'));
  };
  return (
    <Modal id="login">
      <div className={styles.container}>
        <h3 className={styles.title}>{t('modal.title')}</h3>
        <form className={styles.form}>
          <Input className={styles.input} placeholder={t('modal.email')} />
          <Input className={styles.input} placeholder={t('modal.password')} />
          <button onClick={(e) => handleForgotPassword(e)} className={styles.forgot}>
            {t('modal.forgot')}
          </button>
          <Button size="xl" noArrow className={styles.button}>
            {t('modal.title')}
          </Button>
        </form>
      </div>
    </Modal>
  );
};
