import { createSlice } from '@reduxjs/toolkit';

import { fetchRegister } from './thunk';
import { RegisterInitialState } from './types';

const initialState: RegisterInitialState = {
  loading: false,
  error: '',
  success: false,
};

export const slice = createSlice({
  name: 'register',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRegister.pending, (state) => {
        state.error = '';
        state.loading = true;
      })
      .addCase(fetchRegister.fulfilled, (state) => {
        state.loading = false;
        state.success = true;
      })
      .addCase(fetchRegister.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload as string;
      });
  },
});
