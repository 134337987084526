import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';
import 'swiper/css';
import { SliderItem } from '../SliderItem';

import { sliderList } from './Slider.data';

import './Slider.scss';

export const Slider = () => (
  <div className="slider">
    <Swiper
      autoplay={{
        delay: 0,
      }}
      grabCursor
      slidesPerView="auto"
      speed={5000}
      loop
      spaceBetween={8}
      modules={[Autoplay]}
      slidesOffsetAfter={24}
    >
      {sliderList().map((item, index) => (
        <SwiperSlide key={index}>
          <SliderItem text={item.text} text2={item.text2} price={item.price} />
        </SwiperSlide>
      ))}
    </Swiper>
  </div>
);
