import React, { FC } from 'react';
import classNames from 'classnames';

import { Props } from './Card.types';

import styles from './Card.module.scss';

export const Card: FC<Props> = ({ icon: Icon, text, isReverse }) => (
  <div className={classNames(styles.card, { [styles.card_reverse]: isReverse })}>
    <p dangerouslySetInnerHTML={{ __html: text }} className={styles.text} />
    <div className={styles.icon}>
      <Icon />
    </div>
  </div>
);
