import { combineReducers } from 'redux';
import registerReducer from './register';
import uploadReducer from './upload';
import app from './app';

const rootReducer = combineReducers({
  app,
  register: registerReducer,
  upload: uploadReducer,
});

export default rootReducer;
