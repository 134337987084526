import React, { FC, forwardRef } from 'react';
import classNames from 'classnames';

import { Props } from './Input.types';

import styles from './Input.module.scss';

export const Input: FC<Props> = forwardRef<HTMLInputElement, Props>(
  ({ id, className, ...props }, ref) => (
    <input className={classNames(styles.input, className)} id={id} ref={ref} {...props} />
  ),
);
