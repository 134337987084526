export const PolicyfirstParagraph = ['policy.paragraph.1.1', 'policy.paragraph.1.2'];
export const PolicyThirdParagraph = ['policy.paragraph.3.1', 'policy.paragraph.3.2'];
export const PolicyFirstList = [
  'policy.list.1.1',
  'policy.list.1.2',
  'policy.list.1.3',
  'policy.list.1.4',
];
export const PolicySecondList = [
  'policy.list.2.1',
  'policy.list.2.2',
  'policy.list.2.3',
  'policy.list.2.4',
  'policy.list.2.5',
];
