import React from 'react';
import styles from './PolicyTitle.module.scss';

interface Props {
  title: string;
  hTag?: number;
}

export const PolicyTitle = ({ title, hTag = 2 }: Props) => (
  <>
    {hTag === 1 && <h1 className={styles.title}>{title}</h1>}
    {hTag === 2 && <h2 className={styles.title}>{title}</h2>}
  </>
);
