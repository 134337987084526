import React from 'react';
import { motion } from 'framer-motion';

import { useParallax } from 'react-scroll-parallax';
import { useTranslation } from 'react-i18next';

import { Title } from '../../ui';
import { Card } from '../Card';
import { Container } from '../Container';
import { cardsList } from './Earn.data';

import Video from './assets/images/HQback.mp4';
import styles from './Earn.module.scss';
import useWindowSize from '../../hooks/useWindowSize';

export const Earn = () => {
  const size = useWindowSize();
  const parallax = useParallax<HTMLDivElement>({
    translateY: [-10, 10],
  });

  const { t } = useTranslation();
  const itemAnimation = {
    hidden: {
      y: 100,
      opacity: 0,
    },
    visible: (custom: number) => ({
      y: 0,
      transition: { delay: custom * 0.15, duration: 0.6 },
      opacity: 1,
    }),
  };
  return (
    <motion.div
      initial={size.width > 920 ? 'hidden' : 'visible'}
      whileInView="visible"
      viewport={{ amount: 0.2, once: true }}
      className={styles.earn}
    >
      <div ref={parallax.ref} className={styles.video}>
        <video preload="none" loop muted autoPlay src={size.width > 920 && Video} />
      </div>
      <Container>
        <Title title={t('earn.title')} subtitle={t('earn.subTitle')} className={styles.title} />
        <ul className={styles.list}>
          {cardsList().map((item, index) => (
            <motion.li variants={itemAnimation} custom={index + 1} key={index}>
              <Card isReverse={index % 2 !== 0} {...item} />
            </motion.li>
          ))}
        </ul>
      </Container>
    </motion.div>
  );
};
