import React, { FC } from 'react';
import { createPortal } from 'react-dom';
import classNames from 'classnames';

import { Overlay } from '../Overlay';

import { ModalProps } from './Modal.types';

import { ReactComponent as CloseSvg } from './assets/images/close.svg';
import styles from './Modal.module.scss';

export const Modal: FC<ModalProps> = ({ className, isOpen, close, children, ...props }) =>
  createPortal(
    <div className={classNames(styles.modal, { [styles.modal_open]: isOpen })} {...props}>
      <Overlay isOpen={isOpen} onClick={close} />
      <div className={classNames(styles.container, className)}>
        <button onClick={close} type="button" className={styles.close}>
          <CloseSvg />
        </button>
        {children}
      </div>
    </div>,
    document.body,
  );
