import React from 'react';

import { useTranslation } from 'react-i18next';

import { Modal } from '../../Modal';

import styles from './Error.module.scss';
import { ReactComponent as TelSvg } from '../../Footer/assets/images/tel.svg';

export const Error = () => {
  const { t } = useTranslation();
  return (
    <Modal id="error">
      <div className={styles.container}>
        <span className={styles.title}>{t('error.repeat')}</span>
        <div className={styles.flex}>
          <span>{t('error.contact')}</span>
          <div className={styles.button}>
            <TelSvg />
            <a target="_blank" href="https://t.me/ManagerLafabet" rel="noreferrer">
              @ManagerLafabet
            </a>
          </div>
        </div>
      </div>
    </Modal>
  );
};
