import React from 'react';

import { useTranslation } from 'react-i18next';
import { Container } from '../Container';

import styles from './Faq.module.scss';
import { List } from '../PolicyComponent/PolicyListItem';
import { Paragraph } from '../PolicyComponent/PolicyParagraph';
import { Title } from '../PolicyComponent/PolicyTitle';

export const Faq = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <div className={styles.content}>
        <div className={styles.title}>
          <Title hTag={1} title={t('faq.title.1')} />
        </div>
        <Title title={t('faq.title.2')} />
        <Paragraph data={[t('faq.paragraph.1')]} />
        <Title title={t('faq.title.3')} />
        <Paragraph data={[t('faq.paragraph.2')]} />
        <Title title={t('faq.title.4')} />
        <Paragraph data={[t('faq.paragraph.3')]} />
        <Title title={t('faq.title.5')} />
        <Paragraph data={[t('faq.paragraph.4')]} />
        <List data={[t('faq.list.1'), t('faq.list.2')]} />
        <Title title={t('faq.title.6')} />
        <Paragraph data={[t('faq.paragraph.5')]} />
        <Title title={t('faq.title.7')} />
        <Paragraph data={[t('faq.paragraph.6')]} />
        <a href="https://t.me/ManagerLafabet" rel="noreferrer" target="_blank">
          https://t.me/ManagerLafabet
        </a>
      </div>
    </Container>
  );
};
