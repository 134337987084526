import { useTranslation } from 'react-i18next';
import { icons } from './About.icons';

export const firstList = () => {
  const { Icon1, Icon2, Icon3, Icon4 } = icons;
  const { t } = useTranslation();
  const firstList = [
    {
      icon: Icon1,
      text: t('about.firstList.1'),
    },
    {
      icon: Icon2,
      text: t('about.firstList.2'),
    },
    {
      icon: Icon3,
      text: t('about.firstList.3'),
    },
    {
      icon: Icon4,
      text: t('about.firstList.4'),
    },
  ];
  return firstList;
};
export const secondList = () => {
  const { Icon5, Icon6, Icon7, Icon8 } = icons;
  const { t } = useTranslation();

  const secondList = [
    {
      icon: Icon5,
      text: t('about.secondList.1'),
    },
    {
      icon: Icon6,
      text: t('about.secondList.2'),
    },
    {
      icon: Icon7,
      text: t('about.secondList.3'),
    },
    {
      icon: Icon8,
      text: t('about.secondList.4'),
    },
  ];
  return secondList;
};
