import React from 'react';
import { PolicyComponent } from 'src/components/PolicyComponent';
import RotationVideo from 'src/ui/RotationVideo/RotationVideo';
import { Footer, Header } from '../../components';

import styles from './Policy.module.scss';

export const Policy = () => (
  <>
    <RotationVideo />
    <div className={styles.container}>
      <Header />
      <PolicyComponent />
      <Footer />
    </div>
  </>
);
