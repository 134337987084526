export const ParagraphFirstList = [
  { title: 'conditions.paragraph.1.title', text: 'conditions.paragraph.1.text' },
  { title: 'conditions.paragraph.2.title', text: 'conditions.paragraph.2.text' },
  { title: 'conditions.paragraph.3.title', text: 'conditions.paragraph.3.text' },
  { title: 'conditions.paragraph.4.title', text: 'conditions.paragraph.4.text' },
  { title: 'conditions.paragraph.5.title', text: 'conditions.paragraph.5.text' },
  { title: 'conditions.paragraph.6.title', text: 'conditions.paragraph.6.text' },
  { title: 'conditions.paragraph.7.title', text: 'conditions.paragraph.7.text' },
  { title: 'conditions.paragraph.8.title', text: 'conditions.paragraph.8.text' },
  { title: 'conditions.paragraph.9.title', text: 'conditions.paragraph.9.text' },
  { title: 'conditions.paragraph.10.title', text: 'conditions.paragraph.10.text' },
  { title: 'conditions.paragraph.11.title', text: 'conditions.paragraph.11.text' },
  { title: 'conditions.paragraph.12.title', text: 'conditions.paragraph.12.text' },
  { title: 'conditions.paragraph.13.title', text: 'conditions.paragraph.13.text' },
  { title: 'conditions.paragraph.14.title', text: 'conditions.paragraph.14.text' },
];
export const ParagraphSecondList = [
  { title: 'conditions.paragraph.15.title', text: 'conditions.paragraph.15.text' },
  { title: 'conditions.paragraph.16.title', text: 'conditions.paragraph.16.text' },
  { title: 'conditions.paragraph.17.title', text: 'conditions.paragraph.17.text' },
  { title: 'conditions.paragraph.18.title', text: 'conditions.paragraph.18.text' },
  { title: 'conditions.paragraph.19.title', text: 'conditions.paragraph.19.text' },
  { title: 'conditions.paragraph.20.title', text: 'conditions.paragraph.20.text' },
  { title: 'conditions.paragraph.21.title', text: 'conditions.paragraph.21.text.1' },
  { title: '', text: 'conditions.paragraph.21.text.2' },
  { title: 'conditions.paragraph.22.title', text: 'conditions.paragraph.22.text' },
  { title: 'conditions.paragraph.23.title', text: 'conditions.paragraph.23.text' },
  { title: 'conditions.paragraph.24.title', text: 'conditions.paragraph.24.text' },
  { title: 'conditions.paragraph.25.title', text: 'conditions.paragraph.25.text' },
  { title: 'conditions.paragraph.26.title', text: 'conditions.paragraph.26.text' },
  { title: 'conditions.paragraph.27.title', text: 'conditions.paragraph.27.text' },
  { title: 'conditions.paragraph.28.title', text: 'conditions.paragraph.28.text' },
  { title: 'conditions.paragraph.29.title', text: 'conditions.paragraph.29.text' },
  { title: 'conditions.paragraph.30.title', text: 'conditions.paragraph.30.text' },
  { title: 'conditions.paragraph.31.title', text: 'conditions.paragraph.31.text' },
  { title: 'conditions.paragraph.32.title', text: 'conditions.paragraph.32.text' },
  { title: 'conditions.paragraph.33.title', text: 'conditions.paragraph.33.text' },
  { title: 'conditions.paragraph.34.title', text: 'conditions.paragraph.34.text' },
  { title: 'conditions.paragraph.35.title', text: 'conditions.paragraph.35.text' },
  { title: 'conditions.paragraph.36.title', text: 'conditions.paragraph.36.text' },
  { title: 'conditions.paragraph.37.title', text: 'conditions.paragraph.37.text' },
];
export const ParagraphThirdList = [
  { title: 'conditions.paragraph.38.title', text: 'conditions.paragraph.38.text' },
];
export const ParagraphFourthList = [
  { title: 'conditions.paragraph.39.title', text: 'conditions.paragraph.39.text' },
  { title: 'conditions.paragraph.40.title', text: 'conditions.paragraph.40.text' },
  { title: 'conditions.paragraph.41.title', text: 'conditions.paragraph.41.text' },
  { title: 'conditions.paragraph.42.title', text: 'conditions.paragraph.42.text' },
  { title: 'conditions.paragraph.43.title', text: 'conditions.paragraph.43.text' },
  { title: 'conditions.paragraph.44.title', text: 'conditions.paragraph.44.text' },
  { title: 'conditions.paragraph.45.title', text: 'conditions.paragraph.45.text' },
  { title: 'conditions.paragraph.46.title', text: 'conditions.paragraph.46.text' },
  { title: 'conditions.paragraph.47.title', text: 'conditions.paragraph.47.text' },
  { title: 'conditions.paragraph.48.title', text: 'conditions.paragraph.48.text' },
  { title: 'conditions.paragraph.49.title', text: 'conditions.paragraph.49.text' },
];
export const ParagraphFifthList = [
  { title: 'conditions.paragraph.50.title', text: 'conditions.paragraph.50.text' },
];
export const ParagraphSixthList = [
  { title: 'conditions.paragraph.51.title', text: 'conditions.paragraph.51.text' },
];
export const FirstList = [
  'conditions.list.2.1',
  'conditions.list.2.2',
  '@',
  'conditions.list.2.4',
  'conditions.list.2.5',
];
export const SecondList = ['conditions.list.3.1', 'conditions.list.3.2'];
export const ThirdList = [
  'conditions.list.4.1',
  'conditions.list.4.2',
  'conditions.list.4.3',
  'conditions.list.4.4',
  'conditions.list.4.5',
];
export const FourthList = [
  'conditions.list.5.1',
  'conditions.list.5.2',
  'conditions.list.5.3',
  'conditions.list.5.4',
];
export const FifthList = [
  'conditions.list.6.1',
  'conditions.list.6.2',
  'conditions.list.6.3',
  'conditions.list.6.4',
];
