import React, { FC } from 'react';
import classNames from 'classnames';
import { motion } from 'framer-motion';

import useWindowSize from '../../hooks/useWindowSize';
import { Props } from './Title.types';

import styles from './Title.module.scss';

export const Title: FC<Props> = ({ className, title, subtitle, lineHeight }) => {
  const size = useWindowSize();
  const textAnimation = {
    hidden: {
      x: -100,
      opacity: 0,
    },
    visible: (custom: number) => ({
      transition: { delay: custom * 0.2, duration: 0.6 },
      x: 0,
      opacity: 1,
    }),
  };
  return (
    <motion.div
      initial={size.width > 920 ? 'hidden' : 'visible'}
      whileInView="visible"
      viewport={{ amount: 0.1, once: true }}
      className={classNames(
        styles.container,
        { [styles.container_lineHeight]: lineHeight },
        className,
      )}
    >
      <motion.h3
        variants={textAnimation}
        custom={1}
        dangerouslySetInnerHTML={{ __html: title }}
        className={styles.title}
      />
      {subtitle && (
        <motion.p
          variants={textAnimation}
          custom={2}
          dangerouslySetInnerHTML={{ __html: subtitle }}
          className={styles.subtitle}
        />
      )}
    </motion.div>
  );
};
