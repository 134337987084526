import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const translationEn = require('./en.json');
const translationRu = require('./ru.json');

const resources = {
  en: {
    translation: translationEn,
  },
  ru: {
    translation: translationRu,
  },
};

const lang = navigator.language.includes('ru') ? 'ru' : 'en';

i18n.use(initReactI18next).init({
  resources,
  compatibilityJSON: 'v3',
  lng: lang,

  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
