import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { routesList } from './RoutesList';

export const Router = () => (
  <Routes>
    {Object.values(routesList).map((item, index) => {
      const Element = item.element;
      return <Route path={item.path} element={<Element />} key={index} />;
    })}
  </Routes>
);
