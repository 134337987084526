import React from 'react';

import { useTranslation } from 'react-i18next';
import { Container } from '../Container';

import styles from './Conditions.module.scss';
import { List } from '../PolicyComponent/PolicyListItem';
import { Paragraph } from './ConditionsParagraph';
import { Title } from '../PolicyComponent/PolicyTitle';
import {
  FifthList,
  FirstList,
  FourthList,
  ParagraphFifthList,
  ParagraphFirstList,
  ParagraphFourthList,
  ParagraphSecondList,
  ParagraphSixthList,
  ParagraphThirdList,
  SecondList,
  ThirdList,
} from './Conditions.data';

export const Conditions = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <div className={styles.content}>
        <div className={styles.title}>
          <Title hTag={1} title={t('conditions.title.0')} />
        </div>
        <Title title={t('conditions.title.1')} />
        <Paragraph data={ParagraphFirstList} />
        <Title title={t('conditions.title.2')} />
        <Paragraph data={ParagraphSecondList} />
        <Title title={t('conditions.title.3')} />
        <Title title={t('conditions.title.4')} />
        <Paragraph data={ParagraphThirdList} />
        <List data={[t('conditions.list.1.1'), t('conditions.list.1.2')]} />
        <Title title={t('conditions.title.5')} />
        <Title title={t('conditions.title.6')} />
        <Paragraph data={ParagraphFourthList} />
        <Title title={t('conditions.title.7')} />
        <Paragraph data={ParagraphFifthList} />
        <List data={FirstList} />
        <Paragraph data={ParagraphSixthList} />
        <List data={SecondList} />
        <Paragraph
          data={[{ title: 'conditions.paragraph.52.title', text: 'conditions.paragraph.52.text' }]}
        />
        <List data={ThirdList} />
        <Paragraph
          data={[{ title: 'conditions.paragraph.53.title', text: 'conditions.paragraph.53.text' }]}
        />
        <List data={FourthList} />
        <List data={FifthList} />
      </div>
    </Container>
  );
};
