import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { motion } from 'framer-motion';

import classNames from 'classnames';
import { Pagination, Navigation } from 'swiper';
import useWindowSize from '../../hooks/useWindowSize';
import { bannersList } from '../Banners/Banners.data';
import { Banner } from '../Banner/Banner';

import styles from './BannersSlider.module.scss';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

export const BannersSlider = () => {
  const size = useWindowSize();
  const firstBannerAnimation = {
    hidden: {
      x: -100,
      opacity: 0,
    },
    visible: (custom: number) => ({
      transition: { delay: custom * 0.2, duration: 0.4 },
      x: 0,
      opacity: 1,
    }),
  };
  const secondBannerAnimation = {
    hidden: {
      x: 100,
      opacity: 0,
    },
    visible: (custom: number) => ({
      transition: { delay: custom * 0.2, duration: 0.6 },
      x: 0,
      opacity: 1,
    }),
  };
  return (
    <Swiper
      slidesPerView={size.width > 600 ? 2 : 1}
      className={classNames('mySwiper', styles.wrapper)}
      spaceBetween={30}
      modules={[Pagination, Navigation]}
      grabCursor
      pagination={{
        type: 'progressbar',
      }}
      navigation
    >
      {bannersList().map((item, index) => (
        <SwiperSlide key={index}>
          <motion.div
            variants={index === 0 ? firstBannerAnimation : secondBannerAnimation}
            custom={index + 1}
          >
            <Banner key={index} {...item} />
          </motion.div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};
