import React, { useEffect, useState } from 'react';
import useWindowSize from '../../hooks/useWindowSize';

import styles from './RotationVideo.module.scss';
import video from './assets/rotate-video.mp4';
import rotation from './assets/rotation3.png';

const RotationVideo = () => {
  const [isSafari, setIsSafari] = useState(false);
  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    const Safari = /safari/.test(userAgent);
    if (Safari) {
      setIsSafari(true);
    }
  }, []);
  const size = useWindowSize();
  return (
    <div>
      {!isSafari
        ? size.height < 400 && (
            <div className={styles.video}>
              <video preload="auto" loop muted autoPlay src={video} />
            </div>
          )
        : size.height < 400 && (
            <div className={styles.video}>
              <img src={rotation} alt="rotation" />
            </div>
          )}
    </div>
  );
};
export default RotationVideo;
