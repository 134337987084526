import React from 'react';
import { motion } from 'framer-motion';

import { useTranslation } from 'react-i18next';
import useWindowSize from '../../hooks/useWindowSize';

import { BannersSlider } from '../BannersSlider/BannersSlider';
import { Container } from '../Container';
import styles from './Banners.module.scss';

export const Banners = () => {
  const size = useWindowSize();
  const { t } = useTranslation();

  return (
    <motion.div
      initial={size.width > 920 ? 'hidden' : 'visible'}
      whileInView="visible"
      viewport={{ amount: 0.2, once: true }}
      className={styles.banners}
    >
      <Container>
        <h4 className={styles.title}>{t('create.banners')}</h4>
        <div className={styles.box}>
          <BannersSlider />
        </div>
      </Container>
    </motion.div>
  );
};
