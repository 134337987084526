import React, { FC } from 'react';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';

import { HandleChangeLang, Props } from './LanguageDropdown.type';
import { langsList } from './LanguageDropdown.data';
import './i18n';
import './LanguageDropdown.scss';

export const LanguageDropdown: FC<Props> = ({ className }) => {
  const { i18n } = useTranslation();

  const handleChangeLang: HandleChangeLang = (newValue) => {
    if (newValue && newValue.value) {
      i18n.changeLanguage(newValue.value);
    }
  };

  return (
    <div className={className}>
      <Select
        className="language-dropdown"
        classNamePrefix="language-dropdown"
        options={langsList}
        value={langsList.find((item: { value: string }) => item.value === i18n.language)}
        isSearchable={false}
        onChange={handleChangeLang}
      />
    </div>
  );
};
