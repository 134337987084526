import React from 'react';

import { useTranslation } from 'react-i18next';

import styles from './ConditionsParagraph.module.scss';

interface Array {
  title?: string;
  text: string;
}

interface Props {
  data?: Array[];
}

export const ConditionsParagraph = ({ data }: Props) => {
  const { t } = useTranslation();
  return (
    <>
      {data.map((elem, index) => (
        <p className={styles.paragraph} key={index}>
          <span className={styles.title}>{t(elem.title)}</span>
          {t(elem.text)}
        </p>
      ))}
    </>
  );
};
