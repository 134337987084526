import React from 'react';

import { useTranslation } from 'react-i18next';
import { Input, Button } from '../../../ui';
import { Modal } from '../../Modal';

import styles from '../Login/Login.module.scss';

export const ForgotPass = () => {
  const { t } = useTranslation();

  return (
    <Modal id="forgot">
      <div className={styles.container}>
        <h3 className={styles.title}>{t('modal.forgotTitle')}</h3>
        <form className={styles.form}>
          <Input className={styles.input} placeholder={t('modal.email')} />
          <Button size="xl" noArrow className={styles.button}>
            {t('modal.forgotSend')}
          </Button>
        </form>
      </div>
    </Modal>
  );
};
