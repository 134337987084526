import React, { FC } from 'react';
import classNames from 'classnames';

import { Props } from './MainScreenContainer.types';

import styles from './MainScreenContainer.module.scss';

export const MainScreenContainer: FC<Props> = ({ className, children }) => (
  <div className={classNames(styles.container, className)}>{children}</div>
);
