import React, { FC, useEffect, useRef, useState } from 'react';
import useOnClickOutside from 'src/hooks/useOnClickOutside';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Props } from './TextArea.types';

import styles from './TextArea.module.scss';
import { ReactComponent as Attach } from './images/attach.svg';

export const TextArea: FC<Props> = ({
  setImages,
  placeholder,
  className,
  id,
  textArea,
  setTextArea,
}) => {
  const [files, setFiles] = useState('');
  const { t } = useTranslation();
  const [textAreaFocus, setTextAreaFocus] = useState(false);
  const textRef = useRef(null);
  const setInputValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFiles(
      `${e.target.files[0].name}
       ${e.target.files.length > 1 ? `и еще ${Number(e.target.files.length - 1)}` : ''}`,
      // e.target.files,
    );
    setImages(e.target.files);
  };
  useEffect(() => {
    const defaulValue = t('create.attach');
    setFiles(defaulValue);
  }, [t]);
  const unFocus = () => {
    setTextAreaFocus(false);
  };
  const focus = () => {
    setTextAreaFocus(true);
  };
  useOnClickOutside(textRef, unFocus);
  return (
    <label htmlFor={id}>
      <div className={classNames(textAreaFocus && styles.focusContainer, styles.container)}>
        <input
          onChange={(e) => {
            setInputValue(e);
          }}
          type="file"
          className={styles.attach}
          accept=".jpg, .jpeg, .png"
        />
        <div className={styles.inputArea}>
          <span>{files}</span>
          <Attach />
        </div>
        <textarea
          onChange={(e) => setTextArea(e.target.value)}
          onClick={focus}
          ref={textRef}
          value={textArea}
          className={classNames(styles.area, className)}
          id={id}
          placeholder={placeholder}
        />
      </div>
    </label>
  );
};
