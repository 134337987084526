import React, { FC } from 'react';

import { MyImage } from 'src/ui/MyImage';
import { Props } from './Banner.types';

import styles from './Banner.module.scss';

export const Banner: FC<Props> = ({ country, width, height, position, date, icon, image }) => (
  <div className={styles.banner}>
    <div className={styles.info}>
      <div className={styles.titleFlex}>
        <p className={styles.date}>{date}</p>
        <p className={styles.country}>{country}</p>
      </div>
      {position && <img style={{ width, height }} className={styles.icon2} src={icon} alt={date} />}
      {!position && <img style={{ width, height }} className={styles.icon} src={icon} alt={date} />}
    </div>
    {position && (
      <div className={styles.imageBox2}>
        <MyImage className={styles.image} alt={date} src={{ default: image }} />
      </div>
    )}
    {!position && (
      <div className={styles.imageBox}>
        <MyImage className={styles.image} alt={date} src={{ default: image }} />
      </div>
    )}
  </div>
);
