import { Policy, Main, FaqPage, ConditionsPage } from '../pages';

export const routesList = {
  main: {
    path: '/',
    element: Main,
    withMenu: true,
  },
  policy: {
    path: '/policy',
    element: Policy,
    withMenu: true,
  },
  faq: {
    path: '/faq',
    element: FaqPage,
    withMenu: true,
  },
  Conditions: {
    path: '/conditions',
    element: ConditionsPage,
    withMenu: true,
  },
};
