import React from 'react';
import { ParallaxProvider } from 'react-scroll-parallax';
import './App.scss';
import useMetaTags from './hooks/useMetaTags';

import { ModalError, ModalForgotPass, ModalLogin, ModalRegister } from './components';
import { Router } from './Router/Router';

export const App = () => {
  useMetaTags();
  return (
    <ParallaxProvider>
      <Router />
      <ModalLogin />
      <ModalForgotPass />
      <ModalRegister />
      <ModalError />
    </ParallaxProvider>
  );
};
