import { useTranslation } from 'react-i18next';
import { ReactComponent as Icon1 } from './assets/images/icon1.svg';
import { ReactComponent as Icon2 } from './assets/images/icon2.svg';
import { ReactComponent as Icon3 } from './assets/images/icon3.svg';
import { ReactComponent as Icon4 } from './assets/images/icon4.svg';

export const cardsList = () => {
  const { t } = useTranslation();
  const cardsList = [
    {
      icon: Icon1,
      text: t('earn.cardsList.1'),
    },
    {
      icon: Icon2,
      text: t('earn.cardsList.2'),
    },
    {
      icon: Icon3,
      text: t('earn.cardsList.3'),
    },
    {
      icon: Icon4,
      text: t('earn.cardsList.4'),
    },
  ];
  return cardsList;
};
