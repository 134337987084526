import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const useMetaTags = () => {
  const { t } = useTranslation();
  const title = t('meta.title');
  const description = t('meta.description');
  const documentDefined = typeof document !== 'undefined';
  const metaDescription = document.querySelector("meta[name='description']");
  useEffect(() => {
    if (!documentDefined) return;

    if (document.title !== title) document.title = title;
    const oldDescription = metaDescription.getAttribute('content');
    const newDescription = description || '';
    if (oldDescription !== newDescription) {
      metaDescription.setAttribute('content', newDescription);
    }
  }, [description]);
};

export default useMetaTags;
