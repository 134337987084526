import React, { FC, useEffect } from 'react';

import { hideModal, modalSelectors } from '../../store/app';
import { useAppDispatch, useAppSelector } from '../../store/appHooks';
import { ModalProps } from '../../ui/Modal/Modal.types';
import { Modal as BaseModal } from '../../ui';

export const Modal: FC<ModalProps> = ({ children, className, id }) => {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector((state) => modalSelectors.openById(state, id));

  const close = () => dispatch(hideModal(id));

  useEffect(() => {
    if (isOpen) {
      document.querySelector('body').style.overflow = 'hidden';
    } else {
      document.querySelector('body').style.overflow = 'auto';
    }
  }, [isOpen]);

  return (
    <BaseModal isOpen={isOpen} className={className} close={close}>
      {children}
    </BaseModal>
  );
};
