import { ReactComponent as Icon1 } from './assets/images/icon1.svg';
import { ReactComponent as Icon2 } from './assets/images/icon2.svg';
import { ReactComponent as Icon3 } from './assets/images/icon3.svg';
import { ReactComponent as Icon4 } from './assets/images/icon4.svg';
import { ReactComponent as Icon5 } from './assets/images/icon5.svg';
import { ReactComponent as Icon6 } from './assets/images/icon6.svg';
import { ReactComponent as Icon7 } from './assets/images/icon7.svg';
import { ReactComponent as Icon8 } from './assets/images/icon8.svg';

export const icons = {
  Icon1,
  Icon2,
  Icon3,
  Icon4,
  Icon5,
  Icon6,
  Icon7,
  Icon8,
};
