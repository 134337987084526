import React from 'react';

import { useTranslation } from 'react-i18next';
import { Container } from '../Container';
import {
  PolicyFirstList,
  PolicyfirstParagraph,
  PolicySecondList,
  PolicyThirdParagraph,
} from './PolicyComponent.data';

import styles from './PolicyComponent.module.scss';
import { List } from './PolicyListItem';
import { Paragraph } from './PolicyParagraph';
import { Title } from './PolicyTitle';

export const PolicyComponent = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <div className={styles.content}>
        <div className={styles.title}>
          <Title hTag={1} title={t('policy.title.1')} />
        </div>
        <Paragraph data={PolicyfirstParagraph} />
        <Title title={t('policy.title.2')} />
        <Paragraph data={[t('policy.paragraph.2')]} />
        <List data={PolicyFirstList} />
        <Title title={t('policy.title.3')} />
        <Paragraph data={PolicyThirdParagraph} />
        <Title title={t('policy.title.4')} />
        <Paragraph data={[t('policy.paragraph.4')]} />
        <List data={PolicySecondList} />
        <Title title={t('policy.title.5')} />
        <Paragraph data={[t('policy.paragraph.5')]} />
        <Title title={t('policy.title.6')} />
        <Paragraph data={[t('policy.paragraph.6')]} />
        <Title title={t('policy.title.7')} />
        <Paragraph data={[t('policy.paragraph.7')]} />
        <Title title={t('policy.title.8')} />
        <Paragraph data={[t('policy.paragraph.8')]} />
        <Title title={t('policy.title.9')} />
        <Paragraph data={[t('policy.paragraph.9')]} />
        <a href="mailto:info@lafabet.partners">info@lafabet.partners.</a>
      </div>
    </Container>
  );
};
