import { createReducer } from '@reduxjs/toolkit';
import { hideModal, openModal } from './actions';
import { InitialState } from './types';

const initialState: InitialState = { show: [] };

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(openModal, (state, { payload: id }) => ({
      ...state,
      show: [...state.show, id],
    }))
    .addCase(hideModal, (state, { payload: id }) => ({
      ...state,
      show: state.show.filter((item) => item !== id),
    }));
});
