import { Choice } from './Form.types';

export const customStyles: unknown = {
  valueContainer: () => ({
    maxWidth: '75%',
    whiteSpace: 'nowrap',
    display: 'flex',
    gap: '4px',
    alignItems: 'center',
    height: '45px',
  }),
  multiValue: () => ({
    background: '#e00c6846',
    display: 'flex',
    alignItems: 'center',
    color: 'white ',
    gap: '4px',
    border: '0.6px solid #bd6ab680',
    borderRadius: '5px',
    padding: '5px',
  }),
  multiValueLabel: () => ({
    color: 'white ',
  }),
  placeholder: () => ({
    color: 'white ',
    fontWeight: '400',
    fontSize: '18px',
    lineHeight: '104%',
  }),
};

export const getServices = (value: Choice[]) =>
  value.map((elem: { value: string; label: string }) => elem.value).join();
