import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { Container } from '../Container';
import { Form } from '../Form';

import styles from './Create.module.scss';
import { Banners } from '../Banners';
import { ReactComponent as BackSvg } from './assets/images/back.svg';

export const Create = () => {
  const [isMozila, setisMozila] = useState(false);
  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    const Mozila = /firefox/.test(userAgent);
    if (Mozila) {
      setisMozila(true);
    }
  }, []);
  const { t } = useTranslation();
  const textAnimation = {
    hidden: {
      y: 100,
      opacity: 0,
    },
    visible: {
      transition: { duration: 0.6 },
      y: 0,
      opacity: 1,
    },
  };
  const circleAnimation = {
    hidden: {
      x: -1000,
      opacity: 0,
    },
    visible: {
      transition: { duration: 1 },
      x: 0,
      opacity: 1,
    },
  };
  const secondCircleAnimation = {
    hidden: {
      x: -1000,
      opacity: 0,
    },
    visible: {
      transition: { duration: 1.6 },
      x: 0,
      opacity: 1,
    },
  };
  const thirdCircleAnimation = {
    hidden: {
      x: 1000,
      opacity: 0,
    },
    visible: {
      transition: { duration: 1.6 },
      x: 0,
      opacity: 1,
    },
  };
  return (
    <motion.div
      initial="hidden"
      whileInView="visible"
      viewport={{ amount: 0.1, once: true }}
      id="registration"
      className={classNames(isMozila && styles.mozilla, styles.create)}
    >
      {!isMozila ? (
        <>
          <motion.div variants={circleAnimation} className={styles.background}>
            <BackSvg />
          </motion.div>
          <motion.div variants={secondCircleAnimation} className={styles.secondBackground}>
            <BackSvg />
          </motion.div>
          <motion.div variants={thirdCircleAnimation} className={styles.thirdBackground}>
            <BackSvg />
          </motion.div>
        </>
      ) : (
        <>
          <div className={styles.firefox_background}>
            <div className={styles.firefox_circle} />
            <div className={classNames(styles.firefox_circle, styles.firefox_circle_big)} />
          </div>
          <div className={styles.circle} />
        </>
      )}

      <Container className={styles.container}>
        <motion.h3 variants={textAnimation} className={styles.title}>
          {t('create.title')} <br /> {t('create.subTitle')}
        </motion.h3>
        <Form className={styles.form} />
      </Container>
      <Banners />
    </motion.div>
  );
};
