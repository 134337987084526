import React from 'react';
import { Conditions } from 'src/components/Conditions';
import RotationVideo from 'src/ui/RotationVideo/RotationVideo';
import { Footer, Header } from '../../components';

import styles from './Conditions.module.scss';

export const ConditionsPage = () => (
  <>
    <RotationVideo />
    <div className={styles.container}>
      <Header />
      <Conditions />
      <Footer />
    </div>
  </>
);
