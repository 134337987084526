import { useTranslation } from 'react-i18next';

export const sliderList = () => {
  const { t } = useTranslation();
  const array = [
    {
      text: t('slider.1.text'),
      text2: t('slider.1.text2'),
      price: t('slider.1.price'),
    },
    {
      text: t('slider.2.text'),
      text2: t('slider.2.text2'),
      price: t('slider.2.price'),
    },
    {
      text: t('slider.3.text'),
      text2: t('slider.3.text2'),
      price: t('slider.3.price'),
    },
    {
      text: t('slider.4.text'),
      text2: t('slider.4.text2'),
      price: t('slider.4.price'),
    },
    {
      text: t('slider.5.text'),
      text2: t('slider.5.text2'),
      price: t('slider.5.price'),
    },
    {
      text: t('slider.6.text'),
      text2: t('slider.6.text2'),
      price: t('slider.6.price'),
    },
    {
      text: t('slider.7.text'),
      text2: t('slider.7.text2'),
      price: t('slider.7.price'),
    },
    {
      text: t('slider.8.text'),
      text2: t('slider.8.text2'),
      price: t('slider.8.price'),
    },
    {
      text: t('slider.9.text'),
      text2: t('slider.9.text2'),
      price: t('slider.9.price'),
    },
  ];
  return array;
};
