/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useState, useEffect } from 'react';
import { motion } from 'framer-motion';

import { useForm } from 'react-hook-form';

import { useAppDispatch, useAppSelector } from 'src/store/appHooks';
import classNames from 'classnames';

import { fetchUpload, uploadSelectors } from 'src/store/upload';
import Select from 'react-select';

import { useTranslation } from 'react-i18next';
import useWindowSize from '../../hooks/useWindowSize';

import { Button, Checkbox, Input, TextArea } from '../../ui';
import { Props, Data } from './Form.types';

import styles from './Form.module.scss';
import { customStyles, getServices } from './Form.data';
import { ReactComponent as ShowSvg } from './assets/images/eye.svg';
import { ReactComponent as HideSvg } from './assets/images/eye-off.svg';

export const Form: FC<Props> = () => {
  const { t } = useTranslation();
  const size = useWindowSize();
  const dispatch = useAppDispatch();

  const loading = useAppSelector(uploadSelectors.loading);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [choice, setChoice] = useState<any>([]);
  const [pass, setPass] = useState(false);
  const [select, setSelect] = useState(false);
  const [textArea, setTextArea] = useState('');
  const [files, setFiles] = useState(null);
  const [checkbox, setCheckbox] = useState(false);
  const [checkboxError, setCheckBoxError] = useState(false);
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  const formAnimation = {
    hidden: {
      y: 100,
      opacity: 0,
    },
    visible: (custom: number) => ({
      transition: { delay: custom * 0.2, duration: 0.3 },
      y: 0,
      opacity: 1,
    }),
  };

  const buttonAnimation = {
    hidden: {
      x: 100,
      opacity: 0,
    },
    visible: (custom: number) => ({
      transition: { delay: custom * 0.2, duration: 0.3 },
      x: 0,
      opacity: 1,
    }),
  };

  const selectArray = [
    { value: t('create.select.1.value'), label: t('create.select.1.label') },
    { value: t('create.select.2.value'), label: t('create.select.2.label') },
    { value: t('create.select.3.value'), label: t('create.select.3.label') },
    { value: t('create.select.4.value'), label: t('create.select.4.label') },
    { value: t('create.select.5.value'), label: t('create.select.5.label') },
    { value: t('create.select.6.value'), label: t('create.select.6.label') },
    { value: t('create.select.7.value'), label: t('create.select.7.label') },
    { value: t('create.select.8.value'), label: t('create.select.8.label') },
    { value: t('create.select.9.value'), label: t('create.select.9.label') },
    { value: t('create.select.10.value'), label: t('create.select.10.label') },
    { value: t('create.select.11.value'), label: t('create.select.11.label') },
    { value: t('create.select.12.value'), label: t('create.select.12.label') },
    { value: t('create.select.13.value'), label: t('create.select.13.label') },
    { value: t('create.select.14.value'), label: t('create.select.14.label') },
    { value: t('create.select.15.value'), label: t('create.select.15.label') },
    { value: t('create.select.16.value'), label: t('create.select.16.label') },
  ];
  useEffect(() => {
    if (select) {
      if (choice.length !== 0) {
        setSelect(false);
      }
    }
  }, [choice]);
  const handleShowPassword = () => {
    setPass((prev) => !prev);
  };
  const onSubmit = (data: Data) => {
    if (choice.length !== 0) {
      if (checkbox) {
        const { email, password, contact } = data;
        const services = getServices(choice);
        const information = textArea;
        const obj = {
          username: email,
          email,
          password,
          contact,
          services,
          information,
          images: files,
        };
        dispatch(fetchUpload(obj));
      } else {
        setCheckBoxError(true);
      }
    } else {
      setSelect(true);
    }
  };
  return (
    <motion.form
      onSubmit={handleSubmit(onSubmit)}
      initial={size.width > 920 ? 'hidden' : 'visible'}
      whileInView="visible"
      viewport={{ amount: 0.2, once: true }}
      className={styles.form}
    >
      <motion.fieldset variants={formAnimation} custom={1} className={styles.fieldset}>
        <Input
          id="email"
          {...register('email', {
            required: 'Обязательное поле',
            pattern: {
              value: /\S+@\S+\.\S+/,
              message: 'Не правильный формат почты',
            },
          })}
          placeholder={t('create.username')}
          className={classNames(errors.email && styles.error, styles.input)}
        />
        <div className={styles.password}>
          <div className={styles.show} onClick={handleShowPassword}>
            {!pass ? <ShowSvg /> : <HideSvg />}
          </div>
          <Input
            type={!pass ? 'password' : 'text'}
            id="password"
            {...register('password', {
              required: 'Обязательное поле',
              pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{9,50}$/,
              minLength: {
                value: 9,
                message: 'Пароль должен быть длиннее 9 символов',
              },
            })}
            placeholder={t('create.password')}
            className={classNames(errors.password && styles.error, styles.input)}
          />
        </div>
      </motion.fieldset>
      <motion.fieldset variants={formAnimation} custom={2} className={styles.fieldset}>
        <Select
          placeholder={t('create.selectPlaceholder')}
          isMulti
          closeMenuOnSelect={false}
          classNamePrefix="language-dropdown"
          className={classNames(select && styles.selectError, styles.languageDropdown)}
          options={selectArray}
          value={choice}
          isSearchable={false}
          onChange={(e) => setChoice(e)}
          styles={customStyles}
        />
        <Input
          {...register('contact', {
            required: 'Обязательное поле',
          })}
          placeholder={t('create.contacts')}
          className={styles.input}
        />
      </motion.fieldset>
      <motion.div variants={formAnimation} custom={3}>
        <TextArea
          setImages={setFiles}
          setTextArea={setTextArea}
          textArea={textArea}
          placeholder={t('create.label')}
          className={styles.textarea}
        />
      </motion.div>
      <motion.div variants={formAnimation} custom={4}>
        <Checkbox
          checkbox={checkbox}
          checkboxError={checkboxError}
          setCheckbox={setCheckbox}
          className={styles.checkbox}
          id="agree"
        >
          {t('create.rules.1')}{' '}
          <a className={styles.rule} href="/conditions">
            {t('create.rules.2')}
          </a>
          <a href="/conditions">{t('create.rules.3')}</a>
        </Checkbox>
      </motion.div>
      <motion.div variants={buttonAnimation} custom={5}>
        {errors.password && <div className={styles.passError}>{t('error.pass')}</div>}
        <Button className={styles.button} type="submit">
          {loading ? `${t('create.signup')}...` : t('create.signup')}
        </Button>
      </motion.div>
    </motion.form>
  );
};
