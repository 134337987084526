import React, { FC } from 'react';
import { Props } from './SliderItem.types';

import styles from './SliderItem.module.scss';

export const SliderItem: FC<Props> = ({ text, price, text2 }) => (
  <div className={styles.item}>
    <p className={styles.text}>{text}</p>
    <p className={styles.price}>
      {price} <span>{text2}</span>
    </p>
  </div>
);
