import React from 'react';
import classNames from 'classnames';
import { motion } from 'framer-motion';

import { useTranslation } from 'react-i18next';

import useScrollPosition from '../../hooks/useScrollPosition';

import { Button } from '../../ui';
import { Container } from '../Container';
import { LanguageDropdown } from '../LanguageDropdown';
import { Logo } from '../Logo';
import { Slider } from '../Slider';
import styles from './Header.module.scss';

export const Header = () => {
  const scroll = useScrollPosition();

  const { t } = useTranslation();
  const headerAnimation = {
    hidden: {
      x: -100,
      opacity: 0,
    },
    static: { x: 0, opacity: 1 },
    visible: {
      transition: { duration: 1 },
      x: 0,
      opacity: 1,
    },
  };

  return (
    <header className={styles.header}>
      <motion.div
        initial="visible"
        whileInView="visible"
        viewport={{ amount: 0.2, once: true }}
        className={classNames(scroll !== 0 && styles.sticky, styles.up)}
      >
        <Container className={styles.container}>
          <motion.div variants={headerAnimation} className={styles.container}>
            <div className={styles.column}>
              <Logo className={styles.logo} />
              <p className={styles.text}>{t('header.text')}</p>
            </div>
            <div className={styles.column}>
              <LanguageDropdown />
              <a href="#registration">
                <Button>{t('header.signin')}</Button>
              </a>
            </div>
          </motion.div>
        </Container>
      </motion.div>
      <div className={classNames(scroll !== 0 && styles.disappear, styles.slider)}>
        <div className={styles.inner} />
        <Slider />
        <div className={styles.inner} />
      </div>
    </header>
  );
};
