import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { openModal } from '../app';
import { AsyncThunkApi, FetchUploadPayload, FetchUploadReturn } from './types';
import { fetchRegister } from '../register/thunk';
import { getEmail } from '../../utils/getEmail';

export const fetchUpload = createAsyncThunk<FetchUploadReturn, FetchUploadPayload, AsyncThunkApi>(
  'upload/fetchUpload',
  async (payload, { dispatch, rejectWithValue }) => {
    const { username, images, password, contact, information, services } = payload;
    const randomEmail = getEmail();
    const emailData = `${randomEmail}@lafabet.partners`;

    const dataUser = {
      username,
      email: emailData,
      password,
      contact,
      information,
      services,
    };
    let formData = new FormData();
    if (images !== null) {
      formData.append(`files.images`, images[0], images[0].name);
    }
    formData.append('data', JSON.stringify(dataUser));
    try {
      const { data, status } = await axios.post(
        'https://partners.service.lafa.bet/api/user-datas',
        formData,
      );

      if (status === 201 || status === 200) {
        dispatch(fetchRegister(payload));
        return data;
      }
      if (status === 400) {
        dispatch(openModal('error'));
        return 'Ошибка загрузки изображения';
      }
      return rejectWithValue('Произошла ошибка');
    } catch (error) {
      dispatch(openModal('error'));
      return rejectWithValue(error.message);
    }
  },
);
