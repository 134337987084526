import React, { FC } from 'react';
import classNames from 'classnames';

import { Props } from './Button.types';

import { ArrowIcon } from '../icons';

import styles from './Button.module.scss';

export const Button: FC<Props> = ({
  className,
  children,
  type = 'button',
  onClick,
  size = 'sm',
  noArrow,
}) => (
  <button
    type={type}
    className={classNames(
      styles.button,
      styles[`button_size_${size}`],
      { [styles.button_noArrow]: noArrow },
      className,
    )}
    onClick={onClick}
  >
    <p className={styles.label}>{children}</p>
    {!noArrow && (
      <div className={styles.arrow}>
        <ArrowIcon />
      </div>
    )}
  </button>
);
