import React from 'react';
import RotationVideo from 'src/ui/RotationVideo/RotationVideo';
import { Faq } from '../../components/Faq/Faq';
import { Footer, Header } from '../../components';

import styles from './Faq.module.scss';

export const FaqPage = () => (
  <>
    <RotationVideo />
    <div className={styles.container}>
      <Header />
      <Faq />
      <Footer />
    </div>
  </>
);
