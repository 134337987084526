import React, { useEffect, useState } from 'react';
import { Loading } from 'src/components/Loading';
import RotationVideo from 'src/ui/RotationVideo/RotationVideo';
import { About, Create, Earn, Experience, Footer, Header } from '../../components';

import styles from './Main.module.scss';

export const Main = () => {
  const [timer, setTimer] = useState(false);
  useEffect(() => {
    const timerId = setTimeout(() => {
      setTimer(true);
      clearTimeout(timerId);
    }, 1000);
  });
  return (
    <>
      <RotationVideo />
      {!timer ? (
        <Loading hideSpinner={false} />
      ) : (
        <div className={styles.container}>
          <Header />
          <Experience />
          <About />
          <Earn />
          <Create />
          <Footer />
        </div>
      )}
    </>
  );
};
