import React from 'react';
import classNames from 'classnames';
import { motion } from 'framer-motion';

import { useTranslation } from 'react-i18next';
import { Title } from '../../ui';
import { Card } from '../Card';
import { Container } from '../Container';

import styles from './About.module.scss';
import { firstList, secondList } from './About.data';

import { ReactComponent as BackSvg } from './assets/images/back.svg';
import useWindowSize from '../../hooks/useWindowSize';

export const About = () => {
  const size = useWindowSize();
  const { t } = useTranslation();
  const textAnimation = {
    hidden: {
      y: 100,
      opacity: 0,
    },
    visible: {
      transition: { duration: 0.4 },
      y: 0,
      opacity: 1,
    },
  };
  const buttonAnimation = {
    hidden: {
      opacity: 0,
    },
    visible: {
      transition: { delay: 0.1, duration: 0.3 },
      opacity: 1,
    },
  };
  const itemAnimation = {
    hidden: {
      y: 100,
      opacity: 0,
    },
    visible: (custom: number) => ({
      y: 0,
      transition: { delay: custom * 0.1, duration: 0.8 },
      opacity: 1,
    }),
  };
  const circleAnimation = {
    hidden: {
      x: -1000,
      opacity: 0,
    },
    visible: {
      transition: { duration: 1.6 },
      x: 0,
      opacity: 1,
    },
  };
  return (
    <motion.div
      initial={size.width > 920 ? 'hidden' : 'visible'}
      whileInView="visible"
      viewport={{ amount: 0.2, once: true }}
      className={styles.about}
    >
      <Container className={styles.container}>
        <motion.div variants={circleAnimation} className={styles.background}>
          <BackSvg />
        </motion.div>
        <motion.h3 variants={textAnimation} className={styles.title}>
          {t('about.header.1')} <span> {t('about.header.2')}</span>
        </motion.h3>
        <motion.div variants={buttonAnimation} className={styles.box}>
          <p>{t('about.button')}</p>
        </motion.div>
        <Title
          className={styles.name}
          title={t('about.title.1')}
          subtitle={t('about.subTitle.1')}
        />
        <ul className={classNames(styles.list, styles.list_margin)}>
          {firstList().map((item, index) => (
            <motion.li variants={itemAnimation} custom={index + 1} key={index}>
              <Card isReverse={index % 2 !== 0} {...item} />
            </motion.li>
          ))}
        </ul>
        <Title
          className={styles.name}
          title={t('about.title.2')}
          subtitle={t('about.subTitle.2')}
          lineHeight
        />
        <ul className={classNames(styles.list, styles.list_secondMargin)}>
          {secondList().map((item, index) => (
            <motion.li variants={itemAnimation} custom={index + 8} key={index}>
              <Card isReverse={index % 2 !== 1} {...item} />
            </motion.li>
          ))}
        </ul>
      </Container>
    </motion.div>
  );
};
