import React, { FC } from 'react';
import classNames from 'classnames';

import { Props } from './Item.types';

import styles from './Item.module.scss';

export const Item: FC<Props> = ({ className, icon: Icon, text }) => (
  <div className={classNames(styles.item, className)}>
    <div className={styles.icon}>
      <Icon />
    </div>
    <p className={styles.text}>{text}</p>
  </div>
);
