/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Container } from '../Container';
import { Social } from '../Social';

import { socialList } from './Footer.data';

import styles from './Footer.module.scss';

export const Footer = () => {
  const { t } = useTranslation();
  return (
    <footer className={styles.footer}>
      <Container className={styles.container}>
        <ul className={styles.socialList}>
          {/* <p className={styles.label}>1</p> */}
          {socialList.map((item, index) => (
            <li key={index}>
              <Social {...item} />
            </li>
          ))}
        </ul>
        <div className={styles.contacts}>
          <p className={styles.label}>{t('footer.contacts')}</p>
          <a href="mailto:info@lafabet.com" className={styles.email}>
            info@lafabet.com
          </a>
        </div>
        <ul className={styles.links}>
          <li>
            <a href="/faq" className={styles.link}>
              {t('footer.faq')}
            </a>
          </li>
          <li>
            <a href="/conditions" className={styles.link}>
              {t('footer.terms')}
            </a>
          </li>
        </ul>
        {/* <p className={styles.lafabet}>© lafabet 2022</p> */}
      </Container>
      <a href="/policy" className={styles.policy}>
        {t('footer.policy')}
      </a>
    </footer>
  );
};
