import classNames from 'classnames';
import React, { FC } from 'react';

import styles from './Overlay.module.scss';
import { Props } from './Overlay.types';

export const Overlay: FC<Props> = ({ className, isOpen, ...props }) => (
  <div
    className={classNames(styles.overlay, { [styles.overlay_open]: isOpen }, className)}
    {...props}
  />
);
