import { useTranslation } from 'react-i18next';
import Icon1 from './assets/images/icon1.png';
import Icon2 from './assets/images/icon2.png';
import Icon3 from './assets/images/igb.png';
import Icon4 from './assets/images/aw.png';
import Image1 from './assets/images/image1.jpg';
import Image2 from './assets/images/image2.jpg';
import Image3 from './assets/images/barcelona.jpg';
import Image4 from './assets/images/amsterdam.jpg';

export const bannersList = () => {
  const { t } = useTranslation();
  const bannersList = [
    {
      position: false,
      date: t('create.date.4'),
      icon: Icon3,
      image: Image4,
      width: '60%',
      height: '25%',
      country: t('create.country.3'),
    },
    {
      date: t('create.date.3'),
      icon: Icon4,
      image: Image3,
      position: false,
      width: '45%',
      height: '40%',
      country: t('create.country.4'),
    },
    {
      date: t('create.date.1'),
      icon: Icon1,
      image: Image1,
      position: true,
      width: '75%',
      height: '55%',
      country: t('create.country.1'),
    },
    {
      position: false,
      date: t('create.date.2'),
      icon: Icon2,
      image: Image2,
      width: '55%',
      height: '55%',
      country: t('create.country.2'),
    },
  ];
  return bannersList;
};
