import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './PolicyListItem.module.scss';

interface Props {
  data: string[];
}

export const PolicyListItem = ({ data }: Props) => {
  const { t } = useTranslation();
  return (
    <ul className={styles.list}>
      {data.map((elem, index) => (
        <li key={index}>
          <span>
            {t(elem) === '@' ? (
              <span>
                {t('conditions.email')}
                <a style={{ marginLeft: '5px' }} href="mailto:info@lafebat.com">
                  info@lafebat.com
                </a>
              </span>
            ) : (
              t(elem)
            )}
          </span>
        </li>
      ))}
    </ul>
  );
};
