import classNames from 'classnames';
import React from 'react';

import { ReactComponent as Logo } from './assets/logo.svg';

import './Loading.scss';

export const Loading = ({ hideSpinner = false }: { hideSpinner?: boolean }) => (
  <div className="loader">
    <Logo />
    <div
      className={classNames('loader-wrapper', {
        'loader-wrapper--hidden': hideSpinner,
      })}
    />
  </div>
);
