import React, { FC } from 'react';
import classNames from 'classnames';

import { Props } from './Checkbox.types';
import { ReactComponent as Accept } from './assets/images/check.svg';
import styles from './Checkbox.module.scss';

export const Checkbox: FC<Props> = ({
  checkbox,
  checkboxError,
  setCheckbox,
  className,
  children,
  id,
}) => (
  <label className={classNames(styles.checkbox, className)} htmlFor={id}>
    <input onClick={() => setCheckbox((prev) => !prev)} type="checkbox" id={id} />
    <div className={classNames(checkboxError && !checkbox && styles.error, styles.decor)}>
      <div className={styles.accept}>
        <Accept />
      </div>
    </div>
    <p className={styles.label}>{children}</p>
  </label>
);
