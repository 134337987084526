import { Languages } from './LanguageDropdown.type';

export const langsList: Languages[] = [
  {
    label: 'Ru',
    value: 'ru',
  },
  {
    label: 'En',
    value: 'en',
  },
];
