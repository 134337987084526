import { slice } from './slice';

const { reducer, actions } = slice;

export const { reset: registerReset } = actions;

export * from './thunk';
export * as uploadSelectors from './selectors';

export default reducer;
