import React, { FC } from 'react';
import classNames from 'classnames';

import { Props } from './Logo.types';

import { ReactComponent as LogoSvg } from './assets/images/logo.svg';

import styles from './Logo.module.scss';

export const Logo: FC<Props> = ({ className }) => (
  <a href="/">
    <div className={classNames(styles.container, className)}>
      <LogoSvg />
    </div>
  </a>
);
